// src/components/Property/PropertyAnalytics.js
import React from "react";
import { Box, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const PropertyAnalytics = () => (
  <DashboardLayout>
    <DashboardNavbar />
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">Property Analytics</Typography>
      <Typography variant="body1">Analytics data will be displayed here.</Typography>
    </Box>
  </DashboardLayout>
);

export default PropertyAnalytics;
