// src/examples/Sidenav/SidenavCollapse.js

import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";
import { useMaterialUIController } from "context";

function SidenavCollapse({
  icon,
  name,
  active = false,
  isMainTab = false,
  isSubtab = false,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  // Define styles for main tabs and subtabs
  const itemStyle = {
    ...(isMainTab && {
      fontWeight: "bold",
      borderLeft: active ? `4px solid ${sidenavColor}` : "none",
      color: active ? "white" : "text.primary", // Use white for active items
      mb: 0.5,
    }),
    ...(isSubtab && {
      pl: 3,
      fontWeight: "medium",
      bgcolor: active ? `${sidenavColor}.main` : "transparent",
      color: active ? "white" : "text.secondary", // Use white for active items
      borderRadius: 0.5,
      ml: 2,
      mb: 0.3,
    }),
  };

  return (
    <ListItem component="li" disablePadding>
      <MDBox
        {...rest}
        sx={(theme) => ({
          ...collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          }),
          ...itemStyle,
        })}
      >
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
              customColor: active ? "white" : null, // Ensure text is white for active items
            })
          }
        />
      </MDBox>
    </ListItem>
  );
}

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  isMainTab: PropTypes.bool,
  isSubtab: PropTypes.bool,
};

export default SidenavCollapse;
