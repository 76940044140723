import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card, CardContent, Button } from "@mui/material";

const AgencyManage = () => {
  const agents = [
    { id: 1, name: "John Doe", assignedProperties: 10 },
    { id: 2, name: "Jane Smith", assignedProperties: 7 },
    { id: 3, name: "David Johnson", assignedProperties: 12 },
  ];

  return (
    <MDBox p={2}>
      <MDTypography variant="h4" gutterBottom>
        Manage Agents
      </MDTypography>
      {agents.map((agent) => (
        <Card key={agent.id} sx={{ mb: 2 }}>
          <CardContent>
            <MDTypography variant="h6">{agent.name}</MDTypography>
            <MDTypography variant="body2">
              Assigned Properties: {agent.assignedProperties}
            </MDTypography>
            <Button variant="contained" color="primary" sx={{ mt: 1 }}>
              Assign More Properties
            </Button>
          </CardContent>
        </Card>
      ))}
    </MDBox>
  );
};

export default AgencyManage;
