import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";

import Auth0ProviderWithHistory from './auth0-provider-with-history';
import App from "./App";
import { MaterialUIControllerProvider } from "./context";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <CssBaseline />
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);
