// src/pages/Property/PropertyFormPage.js

import React from "react";
import PropertyForm from "components/Property/PropertyForm";

const PropertyFormPage = () => {
  return <PropertyForm />;
};

export default PropertyFormPage;
