// src/pages/Property/PropertyListPage.js

import React from "react";
import PropertyList from "components/Property/PropertyList";

const PropertyListPage = () => {
  return <PropertyList />;
};

export default PropertyListPage;
