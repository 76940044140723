import React, { useState } from "react";
import { Box, InputBase, IconButton, Button, Typography, Card, CardContent, CardMedia, Divider } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const HomeBanner = () => {
  const [selectedTab, setSelectedTab] = useState('latest');

  const listings = [
    {
      id: 1,
      image: `${process.env.PUBLIC_URL}/Apartment-1.jpeg`,
      title: "$3,700+ Studio",
      address: "West Wharf | 60 Wharf Dr, Brooklyn, NY",
      prices: ["$3,700+", "$5,317+", "$6,508+", "$9,121+"],
      description: "Studio, 1bd, 2bd, 3bd",
    },
    {
      id: 2,
      image: `${process.env.PUBLIC_URL}/Apartment-2.jpeg`,
      title: "$3,100+ Studio",
      address: "The Agora | 200 Fulton St, Brooklyn, NY",
      prices: ["$3,100+"],
      description: "Studio",
    },
  ];

  return (
    <>
      {/* Section for Search Box */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px 0',
          width: '100%',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#fff',
            borderRadius: '40px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '10px 20px',
            maxWidth: '800px',
            width: '100%',
            alignItems: 'center',
            '@media (max-width: 600px)': {
              maxWidth: '100%', // Use full width on mobile
              padding: '8px 16px',
            },
          }}
        >
          <InputBase
            sx={{ flex: 1, padding: '10px', fontSize: '16px' }}  // Adjusted for mobile scaling
            placeholder="Enter an address, neighborhood, city, or ZIP code"
            inputProps={{ 'aria-label': 'Enter an address, neighborhood, city, or ZIP code' }}
          />
          <IconButton type="submit" sx={{ padding: '10px' }}>
            <SearchIcon sx={{ fontSize: '28px', color: '#007FFF' }} />
          </IconButton>
        </Box>
      </Box>

      {/* Section for Latest and For You */}
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'space-around', 
          padding: '0',
        }}
      >
        <Button
          onClick={() => setSelectedTab('latest')}
          sx={{
            color: 'gray',
            fontWeight: 'bold',
            fontSize: '18px', // Increased font size
            textTransform: 'none',
            borderBottom: selectedTab === 'latest' ? '2px solid red' : 'none', // Thinner underline
            borderRadius: '0',
            padding: '0 10px',
            justifyContent: 'center', // Center the text
            flexGrow: 0, // Prevent buttons from stretching
            width: 'auto', // Allow button to fit the text size
          }}
        >
          Latest
        </Button>

        {/* Vertical Divider */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: '30px', // Adjust height to match button height
            mx: 2, // Add horizontal margin between buttons
            borderColor: 'gray', // Set divider color
            borderWidth: 2,
          }}
        />

        <Button
          onClick={() => setSelectedTab('forYou')}
          sx={{
            color: 'gray',
            fontWeight: 'bold',
            fontSize: '18px', // Increased font size
            textTransform: 'none',
            borderBottom: selectedTab === 'forYou' ? '2px solid red' : 'none', // Thinner underline
            borderRadius: '0',
            padding: '0 10px',
            justifyContent: 'center', // Center the text
            flexGrow: 0, // Prevent buttons from stretching
            width: 'auto', // Allow button to fit the text size
          }}
        >
          For You
        </Button>
      </Box>

      {/* Listings Section */}
      <Box
        sx={{
          padding: '20px',
          marginTop: '10px',
        }}
      >
        {listings.map(listing => (
          <Card key={listing.id} sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile, side by side on desktop
            marginBottom: '20px',
            width: '100%', // Full width card
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}>
            <CardMedia
              component="img"
              sx={{
                flex: { xs: '0 0 100%', sm: '0 0 auto' }, // Full width on mobile, auto size on desktop
                height: 'auto', // Maintain aspect ratio for the image
              }}
              image={listing.image}
              alt={listing.title}
            />
            <CardContent
              sx={{
                flex: '1', // Let the content grow and take up remaining space
                padding: { xs: '16px', sm: '16px' }, // Adjust padding for mobile and desktop
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography component="div" variant="h6">
                {listing.title}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {listing.address}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' }, // Stack prices vertically on mobile, horizontally on desktop
                  marginTop: '10px',
                  gap: '10px',
                }}
              >
                {listing.prices.map((price, index) => (
                  <Typography key={index} variant="body2" color="text.primary">
                    {price}
                  </Typography>
                ))}
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: '5px' }}>
                {listing.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};

export default HomeBanner;
