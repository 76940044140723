// src/components/Booking/BookingList.js
import React from "react";
import { Box, Typography } from "@mui/material";

const BookingList = () => (
  <Box sx={{ p: 3 }}>
    <Typography variant="h4">Booking List</Typography>
    <Typography variant="body1">List of all bookings will be displayed here.</Typography>
  </Box>
);

export default BookingList;
