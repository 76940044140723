import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card, CardContent } from "@mui/material";

const FinancialReports = () => {
  const financialData = [
    { month: "January", income: 5000, expenses: 1200 },
    { month: "February", income: 5300, expenses: 1400 },
    { month: "March", income: 4700, expenses: 1300 },
  ];

  return (
    <MDBox p={2}>
      <MDTypography variant="h4" gutterBottom>
        Financial Reports
      </MDTypography>
      {financialData.map((data, index) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <MDTypography variant="h6">{data.month}</MDTypography>
            <MDTypography variant="body2">Income: ${data.income}</MDTypography>
            <MDTypography variant="body2">Expenses: ${data.expenses}</MDTypography>
          </CardContent>
        </Card>
      ))}
    </MDBox>
  );
};

export default FinancialReports;
