import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
      sx={{
        fontWeight: 'Normal',
        fontSize: '15px',
        color: '#000',
        '&:hover': { color: '#333', borderBottom: '2px solid #000' },
      }}
    >
      Sign Out
    </Button>
  );
};

export default LogoutButton;
