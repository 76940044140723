import React, { useState } from "react";
import { Grid, TextField, MenuItem, Divider, Typography, Button, useMediaQuery } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import FormLayout from "layouts/forms/FormLayout";
import { createProperty } from "services/propertyService";

const PropertyForm = () => {
  // Hook to detect screen size
  const isSmallScreen = useMediaQuery('(max-width:900px)');

  const [formData, setFormData] = useState({
    landlord: "",
    title: "",
    description: "",
    location: "",
    price_per_night: "",
    number_of_rooms: "",
    bathrooms: "",
    bedrooms: "",
    square_footage: "",
    year_built: "",
    property_type: "apartment",
    amenities: "",
    availability: "available",
    images: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, images: [...e.target.files] });
  };

  const handleReset = () => {
    setFormData({
      landlord: "",
      title: "",
      description: "",
      location: "",
      price_per_night: "",
      number_of_rooms: "",
      bathrooms: "",
      bedrooms: "",
      square_footage: "",
      year_built: "",
      property_type: "apartment",
      amenities: "",
      availability: "available",
      images: [],
    });
  };

  const handleCancel = () => {
    alert("Form has been cancelled");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "images") {
          formData[key].forEach((image) => data.append("images", image));
        } else {
          data.append(key, formData[key]);
        }
      });

      await createProperty(data);
      alert("Property created successfully!");
    } catch (error) {
      console.error("There was an error creating the property!", error);
    }
  };

  return (
    <FormLayout
      title={isSmallScreen ? "" : "Property Registration Form"} // Hide title on small screens
      onReset={handleReset}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      sx={{ mt: 0 }} // Ensure no extra top margin
    >
      <Grid container spacing={isSmallScreen ? 2 : 4} sx={{ px: isSmallScreen ? 1 : 5 }}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              mt: isSmallScreen ? 0 : 1,  // Reduce top margin for smaller screens
              color: "primary.main",
              fontSize: isSmallScreen ? '1.2rem' : '1.5rem'
            }}
          >
            Basic Information
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Landlord"
                name="landlord"
                value={formData.landlord}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={isSmallScreen ? 2 : 3}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              color: "primary.main",
              fontSize: isSmallScreen ? '1.2rem' : '1.5rem'
            }}
          >
            Location & Details
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Price per Night"
                name="price_per_night"
                type="number"
                value={formData.price_per_night}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of Rooms"
                name="number_of_rooms"
                type="number"
                value={formData.number_of_rooms}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Bathrooms"
                name="bathrooms"
                type="number"
                value={formData.bathrooms}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Bedrooms"
                name="bedrooms"
                type="number"
                value={formData.bedrooms}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Square Footage"
                name="square_footage"
                type="number"
                value={formData.square_footage}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Year Built"
                name="year_built"
                type="number"
                value={formData.year_built}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              color: "primary.main",
              fontSize: isSmallScreen ? '1.2rem' : '1.5rem'
            }}
          >
            Additional Details
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Property Type"
                name="property_type"
                value={formData.property_type}
                onChange={handleChange}
                variant="outlined"
                required
              >
                <MenuItem value="apartment">Apartment</MenuItem>
                <MenuItem value="house">House</MenuItem>
                <MenuItem value="condo">Condo</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Amenities"
                name="amenities"
                value={formData.amenities}
                onChange={handleChange}
                variant="outlined"
                helperText="Comma separated list of amenities"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Availability"
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                variant="outlined"
                required
              >
                <MenuItem value="available">Available</MenuItem>
                <MenuItem value="not available">Not Available</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                startIcon={<ImageIcon />}
                sx={{
                  height: "45px",
                  textTransform: "none",
                  py: 1,
                }}
              >
                Upload Images
                <input
                  type="file"
                  hidden
                  onChange={handleImageChange}
                  accept="image/*"
                  multiple
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormLayout>
  );
};

export default PropertyForm;

