import React from "react";
import { Box, Button, Grid, Typography, Divider, TextField } from "@mui/material";

const FormLayout = ({ title, children, onReset, onCancel, onSubmit }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",  // Ensure it takes the full screen height
        display: "flex",      // Flex layout to manage content positioning
        flexDirection: "column",
        justifyContent: "flex-start",  // Align form content to the top
        bgcolor: "background.default",
        p: 3,
        pt: 1,  // Adjust top padding to reduce extra space
      }}
    >
      {title && (
        <>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2, textAlign: "center" }}>
            {title}
          </Typography>
          <Divider sx={{ mb: 3 }} />
        </>
      )}
      <Box component="form" onSubmit={onSubmit} sx={{ flex: 1 }}>
        <Grid container spacing={2}>
          {React.Children.map(children, (child) => {
            if (child.type === TextField) {
              return React.cloneElement(child, {
                InputLabelProps: { shrink: true },
                fullWidth: true, // Make all input fields take full width of their container
              });
            }
            return child;
          })}
        </Grid>
        <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
          {onReset && (
            <Grid item xs={12} sm={4}>
              <Button
                variant="outlined"
                onClick={onReset}
                fullWidth
                sx={{
                  py: 1.5,
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                Reset
              </Button>
            </Grid>
          )}
          {onCancel && (
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                onClick={onCancel}
                fullWidth
                sx={{
                  py: 1.5,
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={onReset || onCancel ? 4 : 12}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                py: 1.5,
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FormLayout;
