import Dashboard from "layouts/dashboard";
import PropertyFormPage from "pages/Property/PropertyFormPage";
import PropertyListPage from "pages/Property/PropertyListPage";
import PropertyAnalytics from "components/Property/PropertyAnalytics";
import FinancialReports from "components/Financial/FinancialReports";
import ProfitLossReport from "components/Financial/ProfitLossReport"; // New component
import ExpenseSummary from "components/Financial/ExpenseSummary"; // New component
import TaxSummary from "components/Financial/TaxSummary"; // New component
import AgencyManage from "components/Agency/AgencyManage";
import MaintenanceRequests from "components/Maintenance/MaintenanceRequests";
import BookingList from "components/Booking/BookingList";
import BookingHistory from "components/Booking/BookingHistory";
import ContractList from "components/Contracts/ContractList";
import ContractUpload from "components/Contracts/ContractUpload";
import ContractDetails from "components/Contracts/ContractDetails";
import EditContract from "components/Contracts/EditContract";
import BillingPage from "components/Billing/BillingPage";
import InvoiceList from "components/Billing/InvoiceList";
import PaymentHistory from "components/Billing/PaymentHistory";
import Scheduling from "components/Scheduling/Scheduling"; // New section
import CreateMeeting from "components/Scheduling/CreateMeeting"; // New section
import ViewMeetings from "components/Scheduling/ViewMeetings"; // New section
import InsightsIcon from "@mui/icons-material/Insights";
import BookIcon from "@mui/icons-material/Book";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import FinancialIcon from "@mui/icons-material/Receipt";
import GroupIcon from "@mui/icons-material/Group";
import CreateIcon from "@mui/icons-material/AddBox";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HistoryIcon from "@mui/icons-material/History";
import BuildIcon from "@mui/icons-material/Build"; 
import ContractIcon from "@mui/icons-material/Assignment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ReceiptIcon from "@mui/icons-material/Receipt"; 
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import DoneIcon from '@mui/icons-material/Done';
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import BarChartIcon from "@mui/icons-material/BarChart";  // For Revenue Overview
import PaidIcon from "@mui/icons-material/Paid";         // For Payments Received
import ScaleIcon from "@mui/icons-material/Scale";       // For Profit & Loss
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"; // For Expense Summary
import CalculateIcon from "@mui/icons-material/Calculate"; // For Tax Summary

const agencyRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon fontSize="small" />, // Updated to new icon
    route: "/dashboard",
    component: <Dashboard />,
    layout: "dashboard",
  },
  {
    type: "collapse",
    name: "Properties",
    key: "properties",
    icon: <HomeWorkIcon fontSize="small" />,
    route: "/properties",
    component: <PropertyListPage />,
    layout: "dashboard",
    collapse: [
      { type: "item", name: "View All Properties", key: "view-all-properties", icon: <ListAltIcon fontSize="small" />, route: "/properties/view", component: <PropertyListPage />, layout: "dashboard" },
      { type: "item", name: "Add New Property", key: "add-new-property", icon: <CreateIcon fontSize="small" />, route: "/properties/add", component: <PropertyFormPage />, layout: "dashboard" },
      { type: "item", name: "Edit Property", key: "edit-property", icon: <EditIcon fontSize="small" />, route: "/properties/edit/:id", component: <PropertyFormPage />, layout: "dashboard" },
      { type: "item", name: "Property Details", key: "property-details", icon: <InfoIcon fontSize="small" />, route: "/properties/details/:id", component: <PropertyFormPage />, layout: "dashboard" },
      { type: "item", name: "Property Analytics", key: "property-analytics", icon: <InsightsIcon fontSize="small" />, route: "/properties/analytics", component: <PropertyAnalytics />, layout: "dashboard" },
    ],
  },
  {
    type: "collapse",
    name: "Bookings",
    key: "bookings",
    icon: <BookIcon fontSize="small" />,
    route: "/bookings",
    component: <BookingList />,
    layout: "dashboard",
    collapse: [
      { type: "item", name: "View All Bookings", key: "view-all-bookings", icon: <ListAltIcon fontSize="small" />, route: "/bookings/view", component: <BookingList />, layout: "dashboard" },
      { type: "item", name: "Edit Booking", key: "edit-booking", icon: <EditIcon fontSize="small" />, route: "/bookings/edit/:id", component: <BookingList />, layout: "dashboard" },
      { type: "item", name: "Booking Details", key: "booking-details", icon: <InfoIcon fontSize="small" />, route: "/bookings/details/:id", component: <BookingList />, layout: "dashboard" },
      { type: "item", name: "Booking History", key: "booking-history", icon: <HistoryIcon fontSize="small" />, route: "/bookings/history", component: <BookingHistory />, layout: "dashboard" },
    ],
  },
  {
    type: "collapse",
    name: "Scheduling", // New Scheduling section
    key: "scheduling",
    icon: <ScheduleIcon fontSize="small" />,
    route: "/scheduling",
    component: <Scheduling />,
    layout: "dashboard",
    collapse: [
      { type: "item", name: "View Meetings", key: "view-meetings", icon: <ListAltIcon fontSize="small" />, route: "/scheduling/view", component: <ViewMeetings />, layout: "dashboard" },
      { type: "item", name: "Create Meeting", key: "create-meeting", icon: <CreateIcon fontSize="small" />, route: "/scheduling/create", component: <CreateMeeting />, layout: "dashboard" },
    ],
  },
  {
    type: "collapse",
    name: "Contract Management",
    key: "contract-management",
    icon: <ContractIcon fontSize="small" />,
    route: "/contracts",
    component: <ContractList />,
    layout: "dashboard",
    collapse: [
      { type: "item", name: "View Contracts", key: "view-contracts", icon: <ListAltIcon fontSize="small" />, route: "/contracts/view", component: <ContractList />, layout: "dashboard" },
      { type: "item", name: "Create/Upload", key: "create-upload-contract", icon: <CreateIcon fontSize="small" />, route: "/contracts/upload", component: <ContractUpload />, layout: "dashboard" },
      { type: "item", name: "Edit Contract", key: "edit-contract", icon: <EditIcon fontSize="small" />, route: "/contracts/edit/:id", component: <EditContract />, layout: "dashboard" },
      { type: "item", name: "Contract Details", key: "contract-details", icon: <InfoIcon fontSize="small" />, route: "/contracts/details/:id", component: <ContractDetails />, layout: "dashboard" },
      { type: "item", name: "Contract History", key: "contract-history", icon: <HistoryIcon fontSize="small" />, route: "/contracts/history", component: <ContractList />, layout: "dashboard" },
    ],
  },
  {
    type: "collapse",
    name: "Maintenance",
    key: "maintenance",
    icon: <BuildIcon fontSize="small" />,  // Updated icon
    route: "/maintenance",
    component: <MaintenanceRequests />,
    layout: "dashboard",
    collapse: [
      { type: "item", name: "View Requests", key: "view-maintenance", icon: <ListAltIcon fontSize="small" />, route: "/maintenance/view", component: <MaintenanceRequests />, layout: "dashboard" },
      { type: "item", name: "Create Request", key: "create-maintenance", icon: <CreateIcon fontSize="small" />, route: "/maintenance/create", component: <MaintenanceRequests />, layout: "dashboard" },
      { type: "item", name: "Completion", key: "mark-completed", icon: <DoneIcon fontSize="small" />, route: "/maintenance/mark-completed", component: <MaintenanceRequests />, layout: "dashboard" },
    ],
  },
  {
    type: "collapse",
    name: "Teams",
    key: "teams",
    icon: <GroupIcon fontSize="small" />,
    route: "/teams",
    component: <AgencyManage />,
    layout: "dashboard",
    collapse: [
      { type: "item", name: "View Teams", key: "view-teams", icon: <ListAltIcon fontSize="small" />, route: "/teams/view", component: <AgencyManage />, layout: "dashboard" },
      { type: "item", name: "Manage Teams", key: "manage-teams", icon: <SupervisorAccountIcon fontSize="small" />, route: "/teams/manage", component: <AgencyManage />, layout: "dashboard" },
    ],
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <AccountBalanceWalletIcon fontSize="small" />, // Replaced icon
    route: "/billing",
    component: <BillingPage />,
    layout: "dashboard",
    collapse: [
      { type: "item", name: "View Invoices", key: "view-invoices", icon: <ListAltIcon fontSize="small" />, route: "/billing/view-invoices", component: <InvoiceList />, layout: "dashboard" },
      { type: "item", name: "Make Payments", key: "make-payments", icon: <CreateIcon fontSize="small" />, route: "/billing/make-payment", component: <BillingPage />, layout: "dashboard" },
      { type: "item", name: "Payment History", key: "payment-history", icon: <HistoryIcon fontSize="small" />, route: "/billing/payment-history", component: <PaymentHistory />, layout: "dashboard" },
    ],
  },
  {
    type: "collapse",
    name: "Financial Reports",
    key: "financial-reports",
    icon: <FinancialIcon fontSize="small" />,
    route: "/financial-reports",
    component: <FinancialReports />,
    layout: "dashboard",
    collapse: [
      { type: "item", name: "Revenue Overview", key: "revenue-overview", icon: <BarChartIcon fontSize="small" />, route: "/financial-reports/revenue", component: <FinancialReports />, layout: "dashboard" },
      { type: "item", name: "Payments Received", key: "payments-received", icon: <PaidIcon fontSize="small" />, route: "/financial-reports/payments", component: <FinancialReports />, layout: "dashboard" },
      { type: "item", name: "Profit & Loss", key: "profit-loss", icon: <ScaleIcon fontSize="small" />, route: "/financial-reports/profit-loss", component: <ProfitLossReport />, layout: "dashboard" },
      { type: "item", name: "Expense Summary", key: "expense-summary", icon: <AttachMoneyIcon fontSize="small" />, route: "/financial-reports/expense-summary", component: <ExpenseSummary />, layout: "dashboard" },
      { type: "item", name: "Tax Summary", key: "tax-summary", icon: <CalculateIcon fontSize="small" />, route: "/financial-reports/tax-summary", component: <TaxSummary />, layout: "dashboard" },
    ],
  },
];

// Function to return the agency routes array
const getRoutes = () => {
  return Array.isArray(agencyRoutes) ? agencyRoutes : [];
};

export default getRoutes;





































