import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Callback = () => {
  const { handleRedirectCallback, isAuthenticated, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        await handleRedirectCallback();

        if (isAuthenticated) {
          navigate("/dashboard");
        } else if (!isLoading) {
          navigate("/authentication/sign-in");
        }
      } catch (err) {
        navigate("/authentication/sign-in");
      }
    };

    handleAuthCallback();
  }, [handleRedirectCallback, isAuthenticated, isLoading, navigate]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error occurred: {error.message}</div>;

  return <div>Loading...</div>;
};

export default Callback;
