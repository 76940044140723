import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Collapse, List, ListItem, ListItemText, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoginIcon from '@mui/icons-material/Person';

const NavBar = () => {
  const { loginWithRedirect } = useAuth0();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openSections, setOpenSections] = useState({});

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleSectionToggle = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <>
      {/* AppBar for Desktop */}
      <AppBar
        position="static"
        elevation={0}
        sx={{
          background: '#fff',
          color: '#000',
          padding: '0 20px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
          minHeight: '80px',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 0',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* Desktop Menu Links */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3 }}>
            {['Buy', 'Rent', 'Sell', 'Home Loans'].map((item) => (
              <Button
                key={item}
                color="inherit"
                sx={{
                  fontWeight: 'Normal',
                  fontSize: '15px',
                  color: '#000',
                  '&:hover': { color: '#333', borderBottom: '2px solid #000' },
                }}
              >
                {item}
              </Button>
            ))}
          </Box>

          {/* Right Side Links */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3, marginLeft: 'auto' }}>
            {['Manage Rentals', 'Advertise', 'Help'].map((item) => (
              <Button
                key={item}
                color="inherit"
                sx={{
                  fontWeight: 'Normal',
                  fontSize: '15px',
                  color: '#000',
                  '&:hover': { color: '#333', borderBottom: '2px solid #000' },
                }}
              >
                {item}
              </Button>
            ))}
            {/* Sign In Button */}
            <Button
              color="inherit"
              onClick={() => loginWithRedirect({ screen_hint: 'login' })}
              sx={{
                fontWeight: 'Normal',
                fontSize: '15px',
                color: '#000',
                '&:hover': { color: '#333', borderBottom: '2px solid #000' },
              }}
            >
              Sign In
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Hamburger Menu Icon */}
      <IconButton
        sx={{ display: { xs: 'flex', md: 'none' }, ml: 1 }}
        onClick={toggleMobileMenu}
      >
        <MenuIcon sx={{ color: 'grey !important' }} />
      </IconButton>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            zIndex: 10,
            textAlign: 'left',
            padding: '10px',
          }}
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 1 }}>
            <IconButton onClick={closeMobileMenu} sx={{ marginLeft: '-10px', marginBottom: '10px', color: 'red' }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Mobile Menu List */}
          <List>
            {['Buy', 'Rent', 'Sell', 'Home Loans', 'Find an Agent', 'Manage Rentals', 'Advertise', 'Help'].map((item, index) => (
              <React.Fragment key={item}>
                <ListItem button onClick={() => handleSectionToggle(item)} sx={{ padding: index === 0 ? '0px 0 5px 0' : '0px 0' }}>
                  <ListItemText primary={item} sx={{ textAlign: 'left', fontSize: '8px' }} />
                  <ExpandMoreIcon sx={{ fontSize: '1.2rem', color: 'blue' }} />
                </ListItem>
                <Divider sx={{ borderColor: '#000', borderWidth: '1px' }} />
                <Collapse in={openSections[item]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button sx={{ paddingLeft: '40px' }}>
                      <ListItemText primary={`Sub-option for ${item}`} sx={{ fontSize: '7px' }} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Box>
      )}

      {/* Mobile Login Icon */}
      <IconButton
        edge="end"
        sx={{
          position: 'absolute',
          top: 10,
          right: 30,
          display: { xs: 'flex', md: 'none' },
        }}
        onClick={() => loginWithRedirect({ screen_hint: 'login' })}
      >
        <LoginIcon sx={{ fontSize: '30px', color: 'grey !important' }} />
      </IconButton>
    </>
  );
};

export default NavBar;
