import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card, CardContent } from "@mui/material";

const MaintenanceRequests = () => {
  const maintenanceRequests = [
    { id: 1, property: "Apartment 101", request: "Leaky faucet", status: "Pending" },
    { id: 2, property: "House 45", request: "Broken window", status: "In Progress" },
    { id: 3, property: "Studio 7", request: "Heating issue", status: "Resolved" },
  ];

  return (
    <MDBox p={2}>
      <MDTypography variant="h4" gutterBottom>
        Maintenance Requests
      </MDTypography>
      {maintenanceRequests.map((req) => (
        <Card key={req.id} sx={{ mb: 2 }}>
          <CardContent>
            <MDTypography variant="h6">{req.property}</MDTypography>
            <MDTypography variant="body2">Request: {req.request}</MDTypography>
            <MDTypography variant="body2">Status: {req.status}</MDTypography>
          </CardContent>
        </Card>
      ))}
    </MDBox>
  );
};

export default MaintenanceRequests;
