import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useAuth0 } from "@auth0/auth0-react";

import HomePage from "pages/Home/HomePage"; // Public Home page
import Callback from "pages/Callback/Callback"; // Callback page for Auth0
import MainLayout from "layouts/MainLayout"; // Layout for authenticated routes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import MDBox from "components/MDBox";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import getRoutesConfig from "routes"; // Import the routes configuration

import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

export default function App() {
  const { isAuthenticated, isLoading } = useAuth0(); // Auth0 hook for auth state
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !controller.openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", "ltr");
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  if (isLoading) return <div>Loading...</div>;

  // Get routes from the routes configuration
  const routes = getRoutesConfig() || [];

  const getFlattenedRoutes = (allRoutes = []) => {
    if (!Array.isArray(allRoutes)) return []; // Check if allRoutes is an array
    let flatRoutes = [];

    allRoutes.forEach((route) => {
      if (route.collapse && Array.isArray(route.collapse)) {
        flatRoutes = flatRoutes.concat(getFlattenedRoutes(route.collapse)); // Recursively flatten nested routes
      } else if (route.route) {
        flatRoutes.push(
          <Route exact path={route.route} element={route.component} key={route.key} />
        );
      }
    });

    return flatRoutes;
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Routes>
        {/* Public Route: Accessible by everyone */}
        <Route path="/" element={<HomePage />} />

        {/* Callback Route for Auth0 */}
        <Route path="/auth/callback" element={<Callback />} />

        {/* Authenticated Routes */}
        {isAuthenticated ? (
          <Route
            path="*"
            element={
              <MainLayout>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  routes={routes} // Pass in the routes to the Sidenav
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                <Routes>{getFlattenedRoutes(routes)}</Routes> {/* Use the flattened routes here */}
              </MainLayout>
            }
          />
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </ThemeProvider>
  );
}
