import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { getProperties } from "services/propertyService";

const sampleProperties = [
  {
    id: 1,
    title: "Cozy Apartment",
    location: "New York",
    price_per_night: 150,
    description: "A cozy apartment with beautiful city views.",
    property_type: "Apartment",
  },
  {
    id: 2,
    title: "Modern Condo",
    location: "Los Angeles",
    price_per_night: 200,
    description: "A modern condo located in the heart of the city.",
    property_type: "Condo",
  },
  {
    id: 3,
    title: "Luxury Villa",
    location: "Miami",
    price_per_night: 400,
    description: "A luxurious villa with all the amenities.",
    property_type: "House",
  },
];

const PropertyList = () => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await getProperties();
        setProperties(response.data);
      } catch (error) {
        console.error("There was an error fetching properties!", error);
        // Fallback to sample data in case of an error
        setProperties(sampleProperties);
      }
    };

    fetchProperties();
  }, []);

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 64px)",
        overflowY: "auto",
        bgcolor: "background.default",
        p: 3,
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3, textAlign: "center" }}>
        Property Listings
      </Typography>

      <Grid container spacing={3}>
        {properties.map((property) => (
          <Grid item xs={12} sm={6} md={4} key={property.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {property.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  {property.location}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {property.description}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  Price per Night: ${property.price_per_night}
                </Typography>
                <Button variant="contained" color="primary">
                  View Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PropertyList;
