// src/layouts/MainLayout/index.js

import React from "react";
import PropTypes from "prop-types";
import { Box, Divider } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const MainLayout = ({ children }) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          minHeight: "calc(100vh - 64px)",
          overflowY: "auto",
          bgcolor: "background.default",
          p: 3,
        }}
      >
        <Divider sx={{ mb: 3 }} />
        {children}
      </Box>
    </DashboardLayout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
