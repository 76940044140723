// src/services/propertyService.js

import axios from 'axios';

const API_URL = 'http://localhost:8000/api';  // Update this with your actual API URL

export const getProperties = async () => {
  try {
    const response = await axios.get(`${API_URL}/properties/`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the properties!", error);
    throw error;
  }
};

export const getPropertyById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/properties/${id}/`);
    return response.data;
  } catch (error) {
    console.error(`There was an error fetching the property with id ${id}!`, error);
    throw error;
  }
};

export const createProperty = async (property) => {
  try {
    const response = await axios.post(`${API_URL}/properties/`, property);
    return response.data;
  } catch (error) {
    console.error("There was an error creating the property!", error);
    throw error;
  }
};
