import React from "react";
import NavBar from "components/NavBar/NavBar";
import HomeBanner from "components/Banner/HomeBanner";
import { Box } from '@mui/material';

const HomePage = () => {
  return (
    <Box sx={{ width: '100%', padding: { xs: '10px', md: '20px' } }}>
      <NavBar />
      <HomeBanner />
    </Box>
  );
};

export default HomePage;
