import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0 hook
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import LogoutButton from "components/Auth/LogoutButton"; // Import the LogoutButton component

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const { isAuthenticated } = useAuth0();
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MenuItem>
        <LogoutButton />
      </MenuItem>
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  if (!isAuthenticated) return null; // Don't render if not authenticated

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...theme.navbar,
        backgroundColor: theme.palette.background.default, // Background color change to match form
        minHeight: 26, // Reduce the navbar height
        height: 26, // Set a smaller height for the navbar
        boxShadow: 'none', // Remove shadow
        borderBottom: 'none', // Remove border
      })}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", minHeight: 36 }}>
        {/* Left side: Hamburger menu (hidden on desktop) */}
        <IconButton
          size="small"
          disableRipple
          color="inherit"
          onClick={handleMiniSidenav}
          sx={{
            marginLeft: 0,
            paddingLeft: 0,
            display: { xs: "block", sm: "none" }, // Show only on mobile (xs), hide on tablet and up (sm)
          }}
        >
          <Icon sx={iconsStyle}>{miniSidenav ? "menu_open" : "menu"}</Icon>
        </IconButton>

        {/* Right side: Other icons */}
        <MDBox sx={{ display: "flex", alignItems: "center", gap: 2, marginLeft: "auto" }}>
          <IconButton
            size="small"
            disableRipple
            onClick={handleOpenMenu}
            sx={{ marginRight: 0, paddingRight: 0 }}
          >
            <Icon sx={iconsStyle}>notifications</Icon>
          </IconButton>
          <IconButton
            size="small"
            disableRipple
            onClick={handleOpenMenu}
            sx={{ marginRight: 0, paddingRight: 0 }}
          >
            <Icon sx={iconsStyle}>account_circle</Icon>
          </IconButton>
          {renderMenu()}
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
