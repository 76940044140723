import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  // Get environment variables for Auth0
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI || `${window.location.origin}/auth/callback`;


  // Handle the redirect callback
  const onRedirectCallback = (appState) => {
    try {
      console.log("Auth0 redirect callback triggered", appState);

      // Redirect to the returnTo path or default to /dashboard
      navigate(appState?.returnTo || "/dashboard");
    } catch (error) {
      console.error("Error during redirect callback:", error);
      // Navigate to error page on failure
      navigate("/error");
    }
  };

  // Ensure domain and clientId are available
  if (!domain || !clientId) {
    console.error("Missing Auth0 configuration in environment variables.");
    return <div>Missing Auth0 configuration. Please check .env file.</div>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,  // Use the value from the environment variable or fallback to default
        audience: process.env.REACT_APP_AUTH0_AUDIENCE, // Optional: Add audience if required for API calls
        scope: 'openid profile email', // Request the necessary scopes for Google authentication
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
